@import "../../../../../../../../../styles/variables.module.scss";


.add_payee_heading{
    font-size:$font-size-heading;
    font-weight: $font-weight-semibold;
    text-align:center;
    margin-bottom:20px;
    font-family: "Poppins";
}
.name-label{
    font-size:$font-size-base !important;
    font-family: "Poppins";
}
.namesecond{
    font-size:$font-size-base !important;
    font-family: "Poppins";
    color:#676767
}
.add_payee_payment_details_heading{
    font-size:$font-size-xl;
    font-weight:$font-weight-medium;
    margin-bottom: 10px;
    font-family: "Poppins";
}

.main_radio_button_container{
    flex-direction: initial;
    margin-left: 3px;
    font-family: "Poppins";

    .main_radio_button {
        span {
            font-family: Poppins;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #000;
            font-family: "Poppins";
        }
    }
}


.country_btn{
    width:30%;
    background-color: $color-secondary !important;
    color:$color-text-primary !important;
    font-family: "Poppins";
    text-transform: none !important;
    outline: none;
}
.active-btn {
    border: 1px solid $color-primary;
    outline:none;

  }