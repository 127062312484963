.overlay-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
  }
   
  .spinner {
    width: 50px; 
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #025041;
    border-radius: 50%;
    animation: spin 1s linear infinite; 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } 