@import "../../../../../styles/variables.module.scss";

.transaction_process_box{
    padding:40px 20px;
    background-color: $color-background-light;
    width:400px !important; 
    font-family: "Poppins";
    border-radius: 8px; 
    .transaction_process_text{
        color: #474747;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-top: 15px;
    }
}
