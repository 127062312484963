@import "../../../../../styles/variables.module.scss";

.makepayment_frame{
    height: auto;
    border-radius: 10px;
    margin:auto;
    padding:24px 18px 24px 18px;
    box-shadow: $box-shadow;
    
}

.makepayment_frame2 {
    display: flex;
    justify-content: center;
    position:relative;
}

.makepayment_frame3{
    width:100%;
    height: auto;
    margin: auto;
}

.makepayment_frame4{
    position: relative;
    display : flex;
    justify-content: center;
}
   
.makepayment_frame5{
    height: auto;
}
@media only screen and (max-width: 1360px){
    .makepayment_frame{
        width:96%;
    }
}

@media only screen and (min-width:1360px) and (max-width: 1650px){
    .makepayment_frame{
        width: 92%;
    }
}

@media only screen and (min-width:1650px) and (max-width: 1800px){
    .makepayment_frame{
        width: 87%;
    }
}



