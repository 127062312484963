@import "../.././../../../styles/variables.module.scss";


.makepayment-invite-niobi-balance{
    background: #FFFFFF;
    border-radius: 8px;
    padding: 38px;
    width: 635px;
    box-shadow: 0 3px 11px hsla(0,2%,61%,.25);
    top:5px;
    &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #292929;
        margin-top: 14px;
        margin-bottom: 32px;
    }

    label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 3px;
        color: #000;
        margin-bottom: 10px;
    }
    .email_label{
        margin-bottom: 0px;
    }
    .email_label:after {
        content: "";
        display: inline-block;
        margin-left: 7px;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgb(255, 71, 93);
    }

    .doc-form-input {
        outline: none;
        padding: 0px 12px;
        height: 45px;
        background: #FFFFFF;
        outline: none;
        font-weight: 400;
        font-size: 12px;
        font-family: 'Poppins';
        font-style: normal;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #474747;
        border-radius: 5px;
        border: 1px solid #E9EAED;

        &::placeholder {
            color: #6D6B6B;
            font-weight: 400;
        }
    }

    .notify_btn_wrapper{
        margin-top:48px;
    }
}
.addphoneno{
    padding-bottom: 20px;
}
@media only screen and (max-width: 1600px) {
    .makepayment-invite-niobi-balance {
        padding: 20px 40px;
        &__heading {
            font-size: 20px;
            margin-bottom: 15px;
        }

    }


}
@media only screen and (max-width:1350px){
    .makepayment-invite-niobi-balance {
        top:5px;
        padding: 5px 30px 16px 34px;
        width:520px;
       label{
        font-size: 12px;
       }

    }
}

@media only screen and (max-width: 450px) {
    .invite-niobi-balance {
        padding: 20px;
        width: 100%;
    }

}

